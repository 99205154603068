@mixin headings($from: 1, $to: 6){
  @for $i from $from through $to{
    h#{$i}, .h#{$i}{
      @content;
    }
  }
}

@mixin theme-mode($mode: light) {
  [data-bs-theme="#{$mode}"] & {
    @content;
  }
}
