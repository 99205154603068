@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import '../../assets/styles/global/variables';
@import '~bootstrap/scss/mixins';
@import '../../assets/styles/global/mixins';

.section--case-studies {
  ~ section {
    position: relative;
  }

  + .section--full-width-content {
    padding-top: 3.2rem;

    @include media-breakpoint-down(lg) {
      padding-top: 0;
    }
  }

  body.single-service & {
    padding-bottom: 5.4rem;

    @include media-breakpoint-down(lg) {
      padding-bottom: 4.75rem;
    }

    &:last-child {
      padding-bottom: 10.75rem;

      @include media-breakpoint-down(lg) {
        padding-bottom: 6.75rem;
      }
    }
  }

  &.has-border {
    margin: 0 auto;
    max-width: calc(100% - 1rem);
    border-radius: 2.5rem;

    @include media-breakpoint-down(sm) {
      padding: 30px 0;
      max-width: 100%;
      border-radius: 1.25rem;
    }

    .case-studies__heading {
      color: $body-color;
    }

    .case-studies__subtitle {
      color: $body-color !important;
    }

    .hero-spinner__group > * {
      fill: $body-color !important;
    }
  }
}

.case-studies {
  &__heading {
    padding-bottom: 3rem;

    @include media-breakpoint-down(lg) {
      padding-bottom: 26px;
    }

    .page-template-template-digital-marketing-agency & {
      .content-wrapper > * {
        max-width: 37.9375rem;
      }

      .text--big {
        font-size: 1.375rem;
        line-height: (28 / 22);

        @include media-breakpoint-down(lg) {
          font-size: 18px;
          line-height: 120%;
        }
      }
    }

    &.case-studies__heading--type-1 {
      padding-bottom: 6.25rem;

      @include media-breakpoint-down(lg) {
        padding-bottom: 2.5rem;
      }

      .case-studies {
        &__subtitle {
          font-size: 2.75rem;
          line-height: (48 / 44);

          @include media-breakpoint-down(lg) {
            font-size: 2rem;
          }
        }
      }

      .content-wrapper {
        margin: 0 auto;
        max-width: 37.9375rem;

        @include media-breakpoint-down(md) {
          margin-top: 1.5rem;
          text-align: center;
        }

        .text--big {
          font-size: 1.375rem;
          line-height: (28 / 22);

          @include media-breakpoint-down(lg) {
            font-size: 18px;
            line-height: 120%;
          }
        }
      }
    }
  }

  &__subtitle {
    //padding-top: 1.5rem;
    font-weight: 600;

    .page-template-template-digital-marketing-agency & {
      font-weight: 500;
      font-size: 1.2rem;

      @include media-breakpoint-up(xl) {
        font-size: 2rem;
      }
    }
  }

  .case-study {
    display: flex;
    justify-content: space-between;
    padding: 1.75rem 2rem;
    border-radius: $border-radius-2xl * 2;
    transform-origin: top center;

    @include media-breakpoint-down(lg) {
      flex-direction: column;
      padding: 14px 12px 18px;
      border-radius: 20px;
    }

    &[style*='background-color:'] {
      @include theme-mode(dark) {
        background-color: #181818 !important;
      }
    }

    .text--small {
      @include media-breakpoint-down(lg) {
        font-size: 13px;
      }
    }

    &__logo {
      position: absolute;
      bottom: 25%;
      left: 50%;
      max-width: 50%;
      max-height: 25%;
      transform: translateX(-50%);
      object-fit: contain;
      object-position: center;

      @include media-breakpoint-down(md) {
        bottom: 24px;
        max-width: 35%;
        max-height: 20%;
      }
    }

    &__company {
      display: flex;
      flex: 0 1 22rem;
      max-width: 22rem;

      @include media-breakpoint-down(lg) {
        flex: 0 0 auto;
        max-width: 100%;
      }

      .of-cover__wrapper {
        overflow: hidden;
        padding-bottom: 0;
        height: 100%;

        @include media-breakpoint-up(xl) {
          min-height: 24rem;
        }

        @include media-breakpoint-down(lg) {
          height: 260px;
        }

        @include media-breakpoint-down(sm) {
          height: 204px;
        }
      }
    }

    &__info {
      flex: 0 1 19.875rem;
      margin-right: $grid-gutter-width;
      max-width: 19.875rem;

      @include media-breakpoint-down(lg) {
        flex: 1 1 100%;
        margin-right: 0;
        max-width: 100%;
      }

      &-wrapper {
        flex: 0 1 53.125rem;
        padding: $grid-gutter-width 0 $grid-gutter-width $grid-gutter-width;
        max-width: 53.125rem;

        @include media-breakpoint-down(lg) {
          padding-top: 10px;
          padding-bottom: 10px;
        }

        @include media-breakpoint-down(lg) {
          flex: 1 1 100%;
          padding: 18px 0 0;
          max-width: 100%;
        }
      }

      .btn {
        @include media-breakpoint-down(lg) {
          min-width: 0;
        }
      }
    }

    &__title {
      font-size: 2.25rem;
      line-height: (44 / 36);

      @include media-breakpoint-down(lg) {
        font-size: 32px;
        line-height: 36px;
      }
    }

    .btn {
      margin-top: 3rem;

      @include media-breakpoint-down(lg) {
        margin-top: 24px;

        @include theme-mode(dark) {
          background-color: $primary;
          color: $white;

          &::after {
            background-image: url('data:image/svg+xml,%3Csvg width=\'41\' height=\'40\' viewBox=\'0 0 41 40\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M10.7036 21.2743H29.3703M29.3703 21.2743L22.7262 13.9088M29.3703 21.2743L22.7262 28.333\' stroke=\'white\' stroke-width=\'1.5\'/%3E%3C/svg%3E%0A');
          }
        }
      }

      @include media-breakpoint-down(md) {
        margin-top: 18px;
      }

      @media (max-width: 460px) {
        width: 200px;
      }
    }

    &__results {
      flex: 0 1 28.375rem;
      max-width: 28.375rem;

      @include media-breakpoint-down(lg) {
        flex: 1 1 100%;
        padding-top: 24px;
        max-width: 100%;
        width: 100%;
      }

      &-title {
        @include media-breakpoint-up(xl) {
          max-width: 19.125rem;
        }
      }

      ul {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        flex-wrap: wrap;
        margin: 2.5rem -4px 0;
        padding: 0;
        list-style: none;

        @include media-breakpoint-down(lg) {
          margin-top: 16px;
        }

        li {
          display: inline-block;
          margin: 4px;
          padding: 7px 14px;
          background-color: $purple;
          color: $white;
          font-weight: 400;
          font-size: $font-size-sm;
          line-height: 16px;

          @include media-breakpoint-down(sm) {
            font-size: 12px;
          }
        }
      }
    }

    &.case-study--manual {
      body.single-service & {
        margin: 1.25rem 0 0;

        @include media-breakpoint-down(md) {
          margin-top: 0;
        }
      }

      .case-study__company {
        display: flex;
        flex: 0 1 30.8rem;
        max-width: 30.8rem;

        @include media-breakpoint-down(lg) {
          flex: 0 0 auto;
          max-width: 100%;
        }

        .of-cover__wrapper {
          @include media-breakpoint-down(lg) {
            min-height: 240px;
          }
        }
      }

      .case-study__info {
        flex: 0 1 30rem;
        margin-right: 0;
        max-width: 30rem;

        @include media-breakpoint-down(lg) {
          flex: 1 1 100%;
          margin-right: 0;
          max-width: 100%;
        }
      }
    }
  }

  .case-studies-slider {
    position: relative;
    //height: calc(100vh - #{$header-desktop-height});

    &.is-grid {
      visibility: visible;
      margin-bottom: -1.75rem;

      @include media-breakpoint-down(lg) {
        margin-bottom: -24px;
        padding-top: 40px;
      }

      @include media-breakpoint-down(md) {
        margin-right: 0;
        margin-left: 0;
        padding-top: 0;
      }

      .splide__slide {
        margin-bottom: 1.25rem;

        > .case-study {
          background-color: $gray-300;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .splide {
    @include media-breakpoint-down(md) {
      margin-right: -12px;
      margin-left: -12px;
    }

    &:not(.is-active) {
      .splide__list {
        display: block !important;
      }
    }

    &.is-active {
      .splide__track {
        padding-top: 40px;

        @include media-breakpoint-down(md) {
          padding-top: 28px;
        }

        &.slide-count-2 {
          padding-top: 20px;

          @include media-breakpoint-down(md) {
            padding-top: 14px;
          }
        }
      }

      .splide__list {
        position: relative;
      }

      .splide__slide {
        position: relative;
        //transition: all 0.2s ease-in-out !important;

        > .case-study {
          transform: none !important;

          > * {
            transition: none !important;
          }
        }

        &:not(.is-active) {
          position: absolute;
          top: 0;
          left: 50%;
          z-index: 2;
          overflow: hidden;
          width: 100%;
          height: 100%;
          opacity: 1;
          transform: scale(0.92) translate(-50%, -40px) !important;
          transform-origin: left top;

          @include media-breakpoint-down(lg) {
            transform: scale(0.9) translate(-50%, -28px) !important;
          }

          > .case-study {
            cursor: pointer;

            > * {
              opacity: 0;
            }
          }
        }

        &.is-active {
          z-index: 5 !important;
          transform: none !important;
          transform: scale(1) translate(0) !important;

          > .case-study > * {
            opacity: 1;
            transition: opacity 0.6s ease-in-out !important;
          }
        }

        &.is-next,
        &.is-next-slide {
          z-index: 4;
          opacity: 1;
          transform: scale(0.96) translate(-50%, -20px) !important;

          @include media-breakpoint-down(lg) {
            transform: scale(0.95) translate(-50%, -14px) !important;
          }

          + .splide__slide {
            z-index: 3;
          }
        }
      }
    }

    &.is-gsap-scroll:not(.is-active) {
      visibility: visible;

      .splide__track {
        overflow: visible;
      }

      .splide__list {
        display: block !important;
      }

      .splide__slide {
        > .case-study {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
        }
      }
    }
  }

  .splide__slide {
    &:nth-child(5n+1) {
      > .case-study {
        background-color: #dcdee0;
      }
    }

    &:nth-child(5n+2) {
      > .case-study {
        background-color: $gray-200;
      }
    }

    &:nth-child(5n+3) {
      > .case-study {
        background-color: $gray-300;
      }
    }

    &:nth-child(5n+4) {
      > .case-study {
        background-color: $white;
      }
    }

    &:nth-child(5n+5) {
      > .case-study {
        background-color: $gray-400;
      }
    }
  }
}

@include color-mode(dark) {
  .case-studies {
    .splide__slide {
      &:nth-child(5n+1) {
        > .case-study {
          background-color: #181818;
        }
      }

      &:nth-child(5n+2) {
        > .case-study {
          background-color: #272727;
        }
      }

      &:nth-child(5n+3) {
        > .case-study {
          background-color: #1e1e1e;
        }
      }

      &:nth-child(5n+4) {
        > .case-study {
          background-color: #343434;
        }
      }

      &:nth-child(5n+5) {
        > .case-study {
          background-color: #0d0d0d;
        }
      }
    }
  }
}

.case-studies__heading--disable-spinner {
  .content-wrapper > * {
    max-width: 37.9375rem;
  }

  .text--big {
    font-size: 1.375rem;
    line-height: (28 / 22);

    @include media-breakpoint-down(lg) {
      font-size: 18px;
      line-height: 120%;
    }
  }

  .case-studies__subtitle {
    font-weight: 500;
    font-size: 1.2rem;

    @include media-breakpoint-up(xl) {
      font-size: 2rem;
    }
  }
}
